import React, { Component } from "react";

import { Link } from "react-router-dom";
import DelayLink from "../../../helpers/delayLink";
import { connect } from "react-redux";

import { CATEGORY_IMAGE_URL, DEFAULT_IMG  } from "../../../../configs/index";

class ShopOurServices extends Component {
    constructor(props){
		super(props);
		
	}
	componentWillReceiveProps(nextProps) {
       // console.log(nextProps); 
    }
  
	render() {
		const { services} = this.props;
		
		return (                        
			<React.Fragment>                         
				<section className="slide-wrapper shop-our-services">
					<div className="slide-holder container">
					  <div className="slider-title">
						<h4 className="title-head">Our Services</h4>
						<h4 className="title-subhead">Art Of Natural Beauty</h4>
						<div className="title-options">
						  
						</div>

					  </div>
					  
					  <ul className="shop-our-services-list homepage">
						{(services.map((item, index) =>  
							<React.Fragment key={index}>  
								<li  key={index}>
								<Link to={{pathname: "/services/"+item.cat_id, state: {cat_name: item.cat_name	}}} >
									<div className="grid-card">  
										<div className="grid-img-holder">
									
											<img src={item.cat_image} alt={item.cat_name} onError={(e)=>{e.target.src=DEFAULT_IMG}} />
									
										</div>
										
									</div>		
									<div className="item-name">{item.cat_name}</div>	
									</Link>									
								</li>									
							</React.Fragment>
						))}
					  </ul>
					<div className="text-center"><a href="/services" className="btn btn-primary" >View More</a></div>
				  </div>
				</section>	                
            </React.Fragment>
            );
	}
}

const mapStateToProps = state => ({
	
});
export default connect(mapStateToProps, {  })(ShopOurServices);