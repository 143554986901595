
/*  export const WEBSITE_URL = "http://localhost/spa1.0";
export const IMAGE_URL = "/assets"; 
 */
/* export const WEBSITE_URL = "http://139.162.42.183/spa1.0/admin";
export const IMAGE_URL = "/spa1.0/admin/assets"; */

export const WEBSITE_URL = "https://fairsignatures.com/admin";
export const IMAGE_URL = "/admin/assets";

/* export const WEBSITE_URL = "https://localhost/fairsignature";
export const IMAGE_URL = "/assets";  */

//Live
/* export const WEBSITE_URL = "https://spajelita.com/admin";
export const IMAGE_URL = "/admin/assets"; */

